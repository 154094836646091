import Head from 'next/head'
import { NextSeo } from 'next-seo'

import config from '@/config'

type IMetaProps = {
  title: string
  description: string
  canonical?: string
  imageUrl?: string | null
}

const Meta = (props: IMetaProps) => {
  return (
    <>
      <Head>
        <meta
          charSet="UTF-8"
          key="charset"
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
      </Head>
      <NextSeo
        title={props.title}
        description={props.description}
        openGraph={{
          title: props.title,
          description: props.description,
          url: props.canonical,
          locale: config.get('locale'),
          site_name: config.get('siteName'),
          images: [
            {
              url: props.imageUrl ?? '',
              alt: props.title,
            },
          ],
        }}
      />
    </>
  )
}

export { Meta }
