export default {
  env: 'production',
  debug: false,
  backendBaseUrl: 'https://peekaboo-backend-vjiloyvjvq-an.a.run.app/rc-v1',
  serverBaseUrl: 'https://peekaboo-server-vjiloyvjvq-an.a.run.app',
  imageKitUrl: 'https://ik.imagekit.io/learners/',
  siteName: 'Peekaboo Studio',
  description: 'Get Professional photoshoots of various concepts',
  locale: 'en',
}
