import { useConceptStore } from '@/stores/concepts'
import { useUserStore } from '@/stores/user'
import { useEffect, useState } from 'react'
import { Concept } from '@/apis/concepts/index.d'
import { useQuery } from 'react-query'
import { fetchConcepts } from '@/apis/concepts'
import { makeLog } from '@/utils/fpixel'
import { useRouter } from 'next/router'
import { toast } from 'react-hot-toast'
import { Loading, Button } from '@nextui-org/react'
import { SinglePackage } from './SinglePackage'
import { PurchaseFailBottomsheet } from '../bottomsheets/PurchaseFailBottomsheet'
import { LoadingContainer } from '../Loading/LoadingContainer'
import { LoginBottomSheet } from '../bottomsheets/LoginBottomSheet'

interface IConceptPackageContainerProps {}

export const ConceptPackageContainer = ({}: IConceptPackageContainerProps) => {
  const router = useRouter()
  const { concepts, updateConcepts } = useConceptStore()
  const { userId, uuid } = useUserStore()

  const [isLoginBottomSheetOpen, setIsLoginBottomSheetOpen] = useState<boolean>(false)
  const [isPurchaseFailBottomSheetOpen, setIsPurchaseFailBottomSheetOpen] = useState<boolean>(false)
  const [dismissable, setDismissable] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedConcepts, setSelectedConcepts] = useState<Concept[]>([])

  const countryCode = 'US'
  const conceptsQuery = useQuery('concepts', async () => fetchConcepts(countryCode))
  const currentPrice = selectedConcepts?.reduce((acc, cur) => acc + cur.price, 0)

  const handleConceptCheck = (id: number) => {
    // concept은 id로 handle
    makeLog('click_concept_price', {
      templateGroupId: id,
      templateName: concepts[id].name,
    })

    if (selectedConcepts?.find((item) => item.id === id)) {
      setSelectedConcepts((prev) => prev?.filter((item) => item.id !== id) ?? [])
    } else {
      setSelectedConcepts((prev) => [...(prev ?? []), concepts[id]])
    }
  }

  const handlePurchase = async (
    userId: number | null,
    uuid: string | null,
    isUserClick: boolean,
  ) => {
    const productIds = selectedConcepts?.map((concept) => Number(concept.productId))
    const conceptIds = selectedConcepts?.map((concept) => concept.id)

    if (!conceptIds?.length || !productIds?.length) {
      makeLog('purchase_try_without_data', {
        templateGroupIds: conceptIds,
        templateNames: selectedConcepts?.map((concept) => concept.name),
        userData: { uuid, userId },
      })
      toast.error('Need to choose concept')
      return
    }

    if (isUserClick) {
      makeLog('click_purchase', {
        templateGroupIds: conceptIds,
        templateNames: selectedConcepts?.map((concept) => concept.name),
      })
    }
    try {
      // TODO currency 바꾸기
      window?.fbq('track', 'AddToCart', {
        content_ids: conceptIds,
        content_type: 'product',
        value: currentPrice,
        currency: 'KRW',
      })
    } catch (e) {
      console.log(e)
    }

    setIsLoginBottomSheetOpen(true)
    makeLog('open_login_modal', {})
    return
  }

  useEffect(() => {
    if (conceptsQuery.isFetched && conceptsQuery.data?.concepts) {
      for (const concept of conceptsQuery.data.concepts) {
        updateConcepts(Number(concept.id), concept)
      }
    }
  }, [conceptsQuery.isFetched, conceptsQuery?.data])

  if (!Object.values(concepts).length) {
    return (
      <div className="mt-4">
        <LoadingContainer />
      </div>
    )
  }

  return (
    <>
      {conceptsQuery?.isLoading ||
        (!concepts && (
          <div className="w-full flex justify-center pt-10">
            <Loading />
          </div>
        ))}

      {concepts &&
        Object.values(concepts)
          .filter((concept) => concept.id !== 1 && concept.id !== 7 && concept.id !== 8)
          .map((concept) => (
            <SinglePackage
              concept={concept}
              selected={selectedConcepts?.find((item) => item.id === concept.id) ? true : false}
              key={concept.id}
              onClick={handleConceptCheck}
            />
          ))}

      <div style={{ height: '100px' }}></div>
      {selectedConcepts?.length && !isLoginBottomSheetOpen ? (
        <div
          className="fixed bottom-0 left-0 right-0 bg-white flex flex-row justify-center"
          style={{ zIndex: 500 }}
        >
          <Button
            className="mb-7 mt-4 w-full mx-10 max-w-md"
            rounded={true}
            style={{ width: '100%', height: '54px' }}
            disabled={!selectedConcepts?.length || isLoading}
            onClick={() => handlePurchase(userId, uuid, true)}
          >
            {isLoading ? (
              <Loading
                size="sm"
                color="white"
              />
            ) : (
              <>Confirm</>
            )}
          </Button>
        </div>
      ) : null}
      <PurchaseFailBottomsheet
        visible={isPurchaseFailBottomSheetOpen}
        onDismiss={() => {
          if (dismissable) {
            setIsPurchaseFailBottomSheetOpen(false)
            setDismissable(false)
          }
        }}
        handlePurchase={() => {
          setIsPurchaseFailBottomSheetOpen(false)
        }}
      />

      <LoginBottomSheet
        currency="USD"
        conceptIds={selectedConcepts?.map((concept) => concept.id)}
        productIds={selectedConcepts?.map((concept) => Number(concept.productId))}
        amount={currentPrice}
        onRequestClose={() => {
          setIsLoginBottomSheetOpen(false)
          makeLog('close_login_modal', {})
        }}
        visible={isLoginBottomSheetOpen}
        onDismiss={() => {
          setIsLoginBottomSheetOpen(false)
        }}
        onAfterLogin={(userId, uuid) => handlePurchase(userId, uuid, false)}
        isLoading={isLoading}
      />
    </>
  )
}
