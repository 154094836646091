import { CountryMap } from '../@types/country.d'

export const countryMap: CountryMap = {
  JP: {
    name: 'Japan',
    code: 'JP',
    currency: 'JPY',
    currencySymbol: '¥',
    currencyFormat: '¥ {amount}',
    price: 1428,
  },
  ID: {
    name: 'Indonesia',
    code: 'ID',
    currency: 'USD',
    currencySymbol: '$',
    currencyFormat: '$ {amount}',
    price: 9.01,
  },
  TH: {
    name: 'Thailand',
    code: 'TH',
    currency: 'THB',
    currencySymbol: '฿',
    currencyFormat: '฿ {amount}',
    price: 313,
  },
  PH: {
    name: 'Philippines',
    code: 'PH',
    currency: 'PHP',
    currencySymbol: '₱',
    currencyFormat: '₱ {amount}',
    price: 512,
  },
  VN: {
    name: 'Vietnam',
    code: 'VN',
    currency: 'USD',
    currencySymbol: '$',
    currencyFormat: '$ {amount}',
    price: 9.01,
  },
  MY: {
    name: 'Malaysia',
    code: 'MY',
    currency: 'MYR',
    currencySymbol: 'RM',
    currencyFormat: 'RM {amount}',
    price: 41,
  },
  SG: {
    name: 'Singapore',
    code: 'SG',
    currency: 'SGD',
    currencySymbol: 'S$',
    currencyFormat: 'S$ {amount}',
    price: 22,
  },
  HK: {
    name: 'Hong Kong',
    code: 'HK',
    currency: 'HKD',
    currencySymbol: 'HK$',
    currencyFormat: 'HK$ {amount}',
    price: 100,
  },
  TW: {
    name: 'Taiwan',
    code: 'TW',
    currency: 'TWD',
    currencySymbol: 'NT$',
    currencyFormat: 'NT$ {amount}',
    price: 278,
  },
  US: {
    name: 'United States',
    code: 'US',
    currency: 'USD',
    currencySymbol: '$',
    currencyFormat: '$ {amount}',
    price: 18,
  },
}
