import { User, UtmInfo } from './index.d'
import { peekabooBackendV1Instance } from '@/apis'

export async function fetchUserByPhone(phone: string): Promise<User> {
  const response = await peekabooBackendV1Instance.get<User>('/users', { params: { phone } })
  if (response.status === 200) {
    return response.data
  }

  throw new Error('User not found')
}

export async function fetchUserByUuid(uuid: string): Promise<User> {
  const response = await peekabooBackendV1Instance.get<User>('/users', { params: { uuid } })
  if (response.status === 200) {
    return response.data
  }

  throw new Error('User not found')
}

export async function createUser(
  name: string,
  phone: string,
  countryCode: string | null,
  utmInfo?: UtmInfo | null,
): Promise<User> {
  const requestBody = utmInfo ? { name, phone, utmInfo, countryCode } : { name, phone, countryCode }

  const response = await peekabooBackendV1Instance.post<User>('/users', requestBody, {
    headers: { 'Content-Type': 'application/json' },
  })

  return response.data
}
