import { LanguageMap } from '../@types/language.d'

export const languageMap: LanguageMap = {
  ja: {
    sale: '[94%割引セール]\nスナップ&ジャケット写真イベント',
    weArePeekaboo: 'こんにちは、\nスタジオピカブです。',
    weUseAI: '私たちはAIを用いれて\nスナップ写真を制作するスタジオです。',
    goToOfficialIG: '公式インスタグラムを見に行く',
    celebrateOpeningOneMonth: '只今、オープン記念に5月一ヶ月間。',
    originalPrice: 'で提供されているスナップ/写真を',
    discountPrice: 'にご提供致します。',
    formBelow:
      '以下のフォームを入力して送信してください。\nお申し込まれた写真をスナップ写真に仕上げてお送り致します。🙂',
    noPhoneNoPhoto: '携帯電話番号が書かれていないと写真のお届けが出来ません。',
    chooseConcept: 'ご希望のコンセプトをお選びください。',
    scrollDownToSeeConcept: '下にスクロールしてさまざまなコンセプトを確認してみてください。',
    hangOn: '少々お待ちください',
  },
  zh: {
    sale: '[94% 折扣]\nSnap & 照片活动',
    weArePeekaboo: '您好,\n这里是Peekaboo工作室。',
    weUseAI: '我們是一家利用AI\n製作快照的工作室。',
    goToOfficialIG: '前往官方 Instagram',
    celebrateOpeningOneMonth: '為了慶祝開業,在5月份',
    originalPrice: '',
    discountPrice: '',
    formBelow: '請填寫下麵的表格並提交，\n我們將會發送給您所申請的畫報照片。🙂',
    noPhoneNoPhoto: '如果沒有手機號碼，將無法收到照片。',
    chooseConcept: '請選擇您想要的主題。',
    scrollDownToSeeConcept: '向下滾動以查看各種主題。',
    hangOn: '请稍等片刻',
  },
}
