export const errorLog = {
  fetchUser: 'fetch_user',
  login: 'login',
  imageUpload: 'image_upload',
  imageDownload: 'image_download',
  imageFilter: 'image_filter',
  modelCreate: 'model_create',
  inputCreate: 'input_create',
  imagePick: 'image_pick',
  purchase: 'purchase',
  regenerate: 'regenerate',
  imageSaveLog: 'image_save_log',
}
