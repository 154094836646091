import * as Sentry from '@sentry/nextjs'
import { PayPalButtons } from '@paypal/react-paypal-js'
import WarningIcon from 'public/icons/warning-icon.svg'
// import { BottomSheet } from 'react-spring-bottom-sheet'
import { BottomSheet } from './BottomSheet'
import { useUserStore } from '@/stores/user'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { User } from '@/apis/users/index.d'
import { UtmInfo } from '@/apis/users/index.d'
import { createUser, fetchUserByPhone } from '@/apis/users'
import { identifyUserProperty, makeLog } from '@/utils/fpixel'
import { toast } from 'react-hot-toast'

import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { E164Number } from 'libphonenumber-js/types'
import { CountryCode } from 'libphonenumber-js/types'

import classNames from 'classnames'
import { createOrder } from '@/apis/order'
import { captureOrder, setOrderFailed } from '@/apis/payments/paypal'
import { useRouter } from 'next/router'
import { errorLog } from '@/utils/errorLogConstants'
import { infoStatus } from '@/utils/infoStatus'

interface RetouchInfoBottomSheetProps {
  visible: boolean
  onRequestClose: () => void
  onDismiss: () => void
  onAfterLogin: (userId: number, uuid: string) => void
  isLoading?: boolean
  productIds: number[]
  conceptIds: number[]
  amount: number
  currency: string
}

// TODO 페이팔 버튼 추가
export const LoginBottomSheet = (props: RetouchInfoBottomSheetProps) => {
  const router = useRouter()
  const {
    visible,
    onDismiss,
    isLoading,
    onAfterLogin,
    onRequestClose,
    productIds,
    conceptIds,
    amount,
    currency,
  } = props
  // const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState<E164Number>('')
  const { name, setName, utmState, countryCode, setCountryCode } = useUserStore()

  const nameRegex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z| ]*$/

  const loginMutation = useMutation<
    User | undefined,
    undefined,
    { name: string; phone: string; utmInfo: UtmInfo | null }
  >({
    mutationFn: async ({ name, phone, utmInfo }) => {
      // 유저 조회
      let user: User | undefined
      try {
        user = await fetchUserByPhone(phone)
      } catch (_) {
        // Ignore
      }
      if (user) return user
      // 유저 생성
      return await createUser(name, phone, countryCode?.toString()?.toUpperCase() ?? null, utmInfo)
    },
    mutationKey: 'login',
  })

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (nameRegex.test(e.target.value)) {
      setName(e.target.value)
    } else {
      toast.error('Name cannot contain special characters or numbers.')
    }
  }

  return (
    <BottomSheet
      isOpen={visible}
      onRequestClose={() => {
        onRequestClose?.()
        setPhone('')
        setLoading(false)
      }}
    >
      <div className="flex flex-col items-start w-full px-14pxr">
        {!loading && (
          <>
            <h2 className="c-h2 mb-6">
              Enter your information below
              <br />
              to proceed with the payment.
            </h2>

            <div className={classNames(['w-full'])}>
              <div className={classNames(['mt-4', 'flex', 'flex-col', 'gap-y-3'])}>
                <PhoneInput
                  addInternationalOption={false}
                  onCountryChange={(country) => {
                    makeLog('change_country_code', {
                      from: countryCode?.toString(),
                      to: country?.toString(),
                    })
                    setCountryCode(country?.toString() ?? 'US')
                  }}
                  defaultCountry={'US' as CountryCode}
                  countries={['US'] as CountryCode[]}
                  numberInputProps={{
                    className: 'border border-black p-4 outline-none w-full',
                    placeholder: 'Phone number',
                  }}
                  value={phone}
                  onChange={(phone) => {
                    if (phone) {
                      setPhone(phone)
                    }
                  }}
                />
              </div>

              <div className={classNames(['mt-1', 'flex', 'flex-col', 'gap-y-3'])}>
                <input
                  className={classNames([
                    'border',
                    'border-black',
                    'p-4',
                    'outline-none',
                    'w-full',
                  ])}
                  placeholder="Name"
                  value={name && nameRegex.test(name) ? String(name) : ''}
                  onChange={handleNameChange}
                />
              </div>

              <div className="flex align-baseline mt-6">
                <div className="pt-4pxr">
                  <WarningIcon
                    alt="warning"
                    width={20}
                    height={20}
                  />
                </div>
                <p className="text-red c-p2 font-bold ml-2">
                  Be sure to enter right phone number to receive your result photos.
                </p>
              </div>
            </div>
          </>
        )}

        <div className="mt-6 w-full">
          <PayPalButtons
            style={{ color: 'silver', shape: 'pill' }}
            disabled={!isValidPhoneNumber(phone) || !name?.length}
            forceReRender={[productIds, conceptIds, amount, currency, phone, name]}
            onClick={() => {
              try {
                window.fbq('track', 'AddToCart', {
                  content_ids: productIds,
                  content_type: 'product',
                  value: amount,
                  currency,
                })
              } catch (e) {
                console.log(e)
                console.log('pixel error')
              }
              makeLog('click_purchase_paypal', {
                currency: currency,
                amount: amount,
                productIds,
                conceptIds,
              })
            }}
            createOrder={async (data, actions) => {
              setLoading(true)
              makeLog('click_login', { phone, name: name })
              // Reset Error Message

              if (!phone) {
                toast.error('Please enter your phone number.')
                setLoading(false)
                return ''
              }

              if (!isValidPhoneNumber(phone)) {
                toast.error('Wrong phone number format.')
                setLoading(false)
                return ''
              }

              if (!name) {
                toast.error('Please enter your name.')
                setLoading(false)
                return ''
              }

              try {
                const user = await loginMutation.mutateAsync({
                  name,
                  phone,
                  utmInfo: utmState,
                })
                const userId = user?.id
                const uuid = user?.uuid
                window?.localStorage.setItem('uuid', uuid!)

                // 로그인 혹은 회원가입 실패시
                if (!userId || !uuid) {
                  toast.error('Login failed. Please try again.')
                  makeLog('login_error', { phone, name })
                  return ''
                }

                makeLog('login_success', {})

                for (const key in user) {
                  if (user.hasOwnProperty(key)) {
                    const value = user[key as keyof User]
                    identifyUserProperty(key, value)
                  }
                }

                const res = await createOrder({
                  productIds: productIds,
                  uuid: uuid!!,
                  pgCode: 'PP',
                })
                console.log('orderId', res.data.orderId)
                return res.data.orderId
              } catch (e: any) {
                makeLog('error_order_create', { error: JSON.stringify(e?.response) })
                Sentry.captureException(e)
                return ''
              }
            }}
            onApprove={async (data, actions) => {
              const uuid = window.localStorage.getItem('uuid')
              try {
                try {
                  await captureOrder({
                    uuid: uuid!,
                    orderId: data.orderID,
                    amount: amount,
                  })

                  makeLog('purchase_success', { orderId: data.orderID })

                  try {
                    window.fbq('track', 'Purchase', {
                      currency: currency,
                      value: amount,
                      content_ids: productIds,
                      content_type: 'product',
                      content_name: 'product',
                    })
                  } catch (e) {
                    console.log('fbq error')
                  }

                  setLoading(false)
                  await router.push(`/info/${infoStatus.PAYMENT_CANCEL}`)
                  return
                } catch (err) {
                  // 최종 승인 실패
                  console.log(err)
                  Sentry.captureException(err)
                  Sentry.addBreadcrumb({
                    category: errorLog.purchase,
                    data: {
                      userId: uuid,
                      orderId: data.orderID,
                    },
                  })
                  makeLog('purchase_fail', { orderId: data.orderID })
                  toast.error(`Payment Failed: ${err}`)
                }

                // 모델 없을시 메시지 전송(모델 생성)
              } catch (err) {
                // 기타 에러
                console.log(err)
                Sentry.captureException(err)
              }
              setLoading(false)
            }}
            onCancel={async (data, actions) => {
              const uuid = window.localStorage.getItem('uuid')
              makeLog('purchase_canceled', { orderId: data.orderID })

              try {
                await setOrderFailed({ uuid: uuid!, orderId: data.orderID as string })
              } catch (err) {
                console.log(err)
                Sentry.captureException(err)
              } finally {
                setLoading(false)
                toast.error(`Payment Canceled`)
              }
            }}
            onError={async (err) => {
              setLoading(false)
              makeLog('purchase_fail', { data: JSON.stringify(err) })
            }}
          />
        </div>
      </div>
    </BottomSheet>
  )
}
