import { ConceptListResponse } from './index.d'
import { Concept } from '@/apis/concepts/index.d'
import { peekabooServerInstance } from '@/apis'

export async function fetchConcepts(countryCode: string): Promise<ConceptListResponse> {
  const response = await peekabooServerInstance.get<ConceptListResponse>(
    `/snow/concepts?countryCode=${countryCode}`,
  )
  return response.data
}

export async function fetchConcept(id: string, countryCode: string): Promise<Concept> {
  const response = await peekabooServerInstance.get<Concept>(
    `/snow/concepts/${id}?countryCode=${countryCode}`,
  )
  return response.data
}
