import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { SnowModel, UtmInfo } from '@/apis/users/index.d'

type Store = {
  userId: number | null
  uuid: string | null
  phoneNumber: string | null
  model: SnowModel | null
  name: string | null
  utmState: UtmInfo | null
  countryCode: string | null
}

type Actions = {
  setPhoneNumber: (phoneNumber: string) => void
  setUserId: (userId: number) => void
  setUuid: (uuid: string) => void
  setUtmState: (utmState: UtmInfo) => void
  clearPhoneNumber: () => void
  setModel: (model: SnowModel | null | undefined) => void
  setName: (name: string) => void
  setCountryCode: (countryCode: string | null) => void
  clearModel: () => void
  clear: () => void
}

export const useUserStore = create(
  persist<Store & Actions>(
    (set) => ({
      userId: null,
      uuid: null,
      phoneNumber: null,
      model: null,
      name: null,
      utmState: null,
      countryCode: null,
      setUtmState: (utmState) => set({ utmState }),
      setUserId: (userId) => set({ userId }),
      setUuid: (uuid) => set({ uuid }),
      setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
      clearPhoneNumber: () => set({ phoneNumber: null }),
      setModel: (model) => set({ model }),
      setName: (name) => set({ name }),
      setCountryCode: (countryCode) => set({ countryCode }),
      clearModel: () => set({ model: null }),
      clear: () => set({ model: null, userId: null, phoneNumber: null }),
    }),
    {
      name: '@peekaboo/user',
    },
  ),
)
