// import { BottomSheet } from 'react-spring-bottom-sheet'
import warningIcon from 'public/icons/warning-icon.png'
import { Button, Card, Text } from '@nextui-org/react'
import { BottomSheet } from './BottomSheet'
import Image from 'next/image'

interface PurchaseFailBottomsheetProps {
  visible: boolean
  onDismiss: () => void
  handlePurchase: () => void
}

export const PurchaseFailBottomsheet = (props: PurchaseFailBottomsheetProps) => {
  const { visible, onDismiss, handlePurchase } = props

  return (
    <BottomSheet
      isOpen={visible}
      onRequestClose={onDismiss}
    >
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-row justify-center items-center">
          <Image
            src={warningIcon}
            alt="warning"
            width={24}
            height={24}
          />
          <p
            className="text-lg font-bold ml-1"
            style={{ color: '#F22E2E' }}
          >
            Payment failed
          </p>
        </div>
        <p className="font-semibold text-xl text-black mt-4 mb-6 text-center">
          Unfortunately, your payment was not successfully processed. <br />
          Try again, or use a different payment method.
        </p>
        <Button
          css={{ height: '47px', width: '133px', marginBottom: '2.5rem' }}
          rounded={true}
          auto={true}
          onPress={handlePurchase}
        >
          Try again
        </Button>
        <Card variant="flat">
          <Card.Body
            css={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text>Peekaboo customer service</Text>
            <a
              className="my-3 font-bold underline"
              href="https://peekaboo.channel.io"
              target="_blank"
            >
              peekaboo.channel.io
            </a>
            <Text>Please contact our customer support if you run into the same problem.</Text>
          </Card.Body>
        </Card>
      </div>
    </BottomSheet>
  )
}
