import { ModalProps } from '@/components/modals/index.d';
import { CustomModal } from '@/components/modals/CustomModal';

type BottomSheetProps = ModalProps & {
  height?: number;
};

export const BottomSheet = ({
  height,
  ...props
}: BottomSheetProps) => (
  <CustomModal
    zIndex={999}
    {...props}
    style={{
      content: {
        width: '100%',
        maxHeight: '90vh',
        maxWidth: '500px',
        inset: 'auto 0 0 50%',
        transform: 'translate(-50%, 0)',
        borderRadius: '1.5rem 1.5rem 0 0',
      },
    }}
  />
);