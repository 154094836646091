import ReactModal from 'react-modal';
import { ModalProps } from './index.d';

export const CustomModal = ({
  title,
  children,
  zIndex = 100,
  overlayBackgroundColor = 'rgba(0, 0, 0, 0.7)',
  ...props
}: ModalProps) => (
  <ReactModal
    ariaHideApp={false}
    onAfterOpen={() => {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.right = '0';
      document.body.style.left = '0';
    }}
    onAfterClose={() => {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.right = '';
      document.body.style.left = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }}
    {...props}
    style={{
      overlay: {
        zIndex,
        backgroundColor: overlayBackgroundColor,
        ...(props.style?.overlay ?? {}),
      },
      content: {
        backgroundColor: 'white',
        border: 0,
        padding: 0,
        borderRadius: '1.5rem',
        boxSizing: 'border-box',
        display: 'flex',
        lineHeight: 1.5,
        ...(props.style?.content ?? {}),
      },
    }}
  >
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '1.5rem 1rem',
        overflow: 'auto',
      }}
    >
      {title && (
        <div
          style={{
            flex: 1,
          }}
        >
          <h2 style={{ fontSize: '1.3rem', fontWeight: '600' }}>{title}</h2>
        </div>
      )}

      <div
        style={{
          flex: 1,
        }}
      >
        {children}
      </div>
    </div>
  </ReactModal>
);