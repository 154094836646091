import classNames from 'classnames'
import { useRouter } from 'next/router'

interface IFooterProps {
  privacyLabel?: string
  refundLabel?: string
  countryCode: string | null
}

export const Footer = ({ privacyLabel, refundLabel, countryCode }: IFooterProps) => {
  const router = useRouter()

  return (
    <>
      <p className={classNames(['text-sm', 'mt-6', 'p-4', 'pb-0', 'text-gray-600'])}>
        {countryCode === 'US' &&
          'TeamLearners Inc. Email: admin@learners.company | Copyright 2023. TeamLearners Inc. All right reserved. |'}
        {countryCode === 'JP' &&
          'チームランナーズ株式会社（TeamLearners Inc.）| 代表：チョン・スンジン | 事業者登録番号：385-87-02889 | 法人登録番号：110111-8541769 | 通信販売業申告番号：第2023-ソウル江南-01114号|住所：ソウル特別市江南区ノンヒョンロ76ギル6、4階402号（駅三洞、ノーマビル） | 有線番号：02-6953-6510 | Email：admin@learners.company | Copyright 2023. TeamLearners Inc. All right reserved.'}
        {(countryCode === 'HK' || countryCode === 'TW') &&
          '團隊學習者公司 | 代表人物：鄭升鎮 | 商業登記號：385-87-02889 | 企業註冊號：110111-8541769 | 郵購業務報告編號：No. 2023-Seoul Gangnam-01114 |地址：#402, 4th Floor, 6, Nonhyeon-ro 76-gil, Gangnam-gu, Seoul (Yeoksam-dong, Noma Building) | 電話號碼：02-6953-6510 | 電子郵件：admin@learners.company | 版權所有 2023. TeamLearners Inc.保留所有權利。'}
      </p>
      <div className="flex w-full justify-center gap-4">
        <p
          className="mt-2 underline cursor-pointer text-center text-gray-600 text-sm"
          onClick={() => {
            if (!countryCode || countryCode === 'US') {
              window.open(
                'https://learnerscompany.notion.site/PRIVACY-NOTICE-01cb30e37ab44fab922d898eb119b16a',
                '_self',
              )
            } else {
              router.push(`/terms/privacy?country_code=${countryCode?.toLowerCase()}`)
            }
          }}
        >
          {privacyLabel ? privacyLabel : 'Privacy Notice'}
        </p>
        <p
          className="mt-2 underline cursor-pointer text-center text-gray-600 text-sm"
          onClick={() => {
            if (!countryCode || countryCode === 'US') {
              window.open(
                'https://learnerscompany.notion.site/PRIVACY-NOTICE-01cb30e37ab44fab922d898eb119b16a',
                '_self',
              )
            } else {
              router.push(`/terms/refund?country_code=${countryCode?.toLowerCase()}`)
            }
          }}
        >
          {refundLabel ? refundLabel : 'Refund Policy'}
        </p>

        {countryCode === 'JP' && (
          <p
            className="mt-2 underline cursor-pointer text-center text-gray-600 text-sm"
            onClick={() => router.push('/terms/law/jp')}
          >
            特定商取引法に基づく表記
          </p>
        )}
      </div>
    </>
  )
}
