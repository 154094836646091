import { peekabooBackendV1Instance } from '@/apis'
import { IOrderRequest, IOrderResponse } from './index.d'
import { AxiosResponse } from 'axios'

export const createOrder = async (
  request: IOrderRequest,
): Promise<AxiosResponse<IOrderResponse>> => {
  return peekabooBackendV1Instance.post<IOrderResponse>(
    '/orders',
    {
      productIds: request.productIds,
      pgCode: request.pgCode,
    },
    {
      headers: {
        'x-user-id': request.uuid,
        'Content-type': 'application/json',
      },
    },
  )
}
