import deepmerge from 'deepmerge'
import nestedProperty from 'nested-property'

import development from './default'
import production from './production'
import preview from './preview'

class Config {
  config: { [x: string]: any }

  constructor(private readonly env: string = 'development') {
    this.config = deepmerge(
      development,
      { development, preview, production }[this.env] as Record<string, any>,
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      },
    )
  }

  get<T>(property: string | string[], defaultValue: T | null = null): T {
    if (Array.isArray(property)) {
      property = property.join('.')
    }

    return (nestedProperty.get(this.config, property) || defaultValue) as T
  }

  has(property: string | string[]): boolean {
    if (Array.isArray(property)) {
      property = property.join('.')
    }

    return nestedProperty.has(this.config, property)
  }
}

// @ts-ignore
const config: Config = new Config(process.env.VERCEL_ENV ?? process.env.NEXT_PUBLIC_VERCEL_ENV)

export default config
