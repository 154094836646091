export const SERVICE_NAME = 'Personal_color'

export interface Concept {
  id: number
  name: string
  productId: number
  price: number
  images: string[]
  description: string | null
}

interface Concepts {
  concepts: Concept[]
}
export const SCARLETT_MARKET_GLOBAL = 'SCARLETT_MARKET_GLOBAL'
export const SCARETT_MARKET = 'SCARLETT_MARKET'
export const PERSONAL_COLOR_CONCEPT = 'STUDIO_TEST_20230404'
export const PERSONAL_COLOR = 'Personal_color'
export const PERSONAL_COLOR_TEMPLATE_GROUP_ID: number[] = [1, 2, 3, 4, 5]
export const SCARLETT_MARKET_TEMPLATE_GROUP_ID: number[] = [6, 7, 8, 9, 10]

export const SCARLETT_CONCEPTS: Concepts = {
  concepts: [
    {
      id: 6,
      name: '꽃의 아이 컨셉',
      productId: 27,
      description: '어느 날, 나는 조금 낯선 곳에 눈을 떴지.',
      price: 12000,
      images: [
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/flower_baby_01.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/flower_baby_02.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/flower_baby_03.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/flower_baby_04.png',
      ],
    },
    {
      id: 7,
      name: '푸르른 하얀빛 컨셉',
      productId: 28,
      description: '하얀 햇빛에 비친 당신의 모습을 담아봐요.',
      price: 12000,
      images: [
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/input_blue_white_01.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/input_blue_white_02.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/input_blue_white_03.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/input_blue_white_04.png',
      ],
    },
    {
      id: 8,
      name: '스트로베리 스카이 컨셉',
      productId: 29,
      description: '벚꽃 가득한 하늘, 날아올라봐요.',
      price: 12000,
      images: [
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/replace_sky_01.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/replace_sky_02.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/replace_sky_03.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/replace_sky_04.png',
      ],
    },
    {
      id: 9,
      name: '진홍빛 향기 컨셉',
      productId: 30,
      description: '붉은 진달래 향기 속의 나를 담아봐요.',
      price: 12000,
      images: [
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/red_flower_01.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/red_flower_02.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/red_flower_03.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/red_flower_input_04.png',
      ],
    },
    {
      id: 10,
      name: '그시절 첫사랑 컨셉',
      productId: 31,
      description: '누군가의 기억 속 첫사랑이 되어봐요.',
      price: 12000,
      images: [
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/japan_input_01.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/japan_input_02.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/japan_input_03.png',
        'https://eyccoloxavmudsdqydsk.supabase.co/storage/v1/object/public/snow-template-images/japan_input_04.png',
      ],
    },
  ],
}

export const CHANNEL_IO_URL = 'https://peekaboo.channel.io'
