import amplitude from 'amplitude-js'
import { SCARETT_MARKET, SERVICE_NAME } from './constants'
import { UtmInfo } from '@/apis/users/index.d'

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID
export const FB_PIXEL_ID2 = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID2
export const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID
export const TIKTOK_ID = process.env.NEXT_PUBLIC_TIKTOK_ID

declare const window: Window &
  typeof globalThis & {
    fbq: any
    ttq: any
    gtag: any
  }

export const makeLog = (event: string, data?: any) => {
  try {
    amplitude.getInstance().logEvent(event, { ...data })
  } catch (e) {
    console.log(`ampltiude error ${event} ${e}`)
  }

  try {
    window!.fbq('trackCustom', event, data)
  } catch (e) {
    console.log('pixel error')
  }

  try {
    window.gtag('event', event, data)
  } catch (e) {
    console.log('gtag error')
  }

  if (event === 'login_success') {
    try {
      window.ttq.track('CompleteRegistration')
    } catch (e) {
      console.log('Tiktok error')
    }
  } else if (event === 'purchase_success') {
    window.ttq.track('CompletePayment')
  }
}

export const identifyUserProperty = (key: 'id' | 'utmInfo' | string, value: any) => {
  if (key === 'id') {
    amplitude.getInstance().setUserId(value)
  } else if (key === 'utmInfo') {
    for (const key in value as UtmInfo) {
      if (value.hasOwnProperty(key)) {
        const utmValue = value[key as keyof UtmInfo]

        if (utmValue) {
          const identify = new amplitude.Identify().setOnce(key, utmValue)
          amplitude.getInstance().identify(identify)
        }
      }
    }
  } else {
    if (value) {
      const identify = new amplitude.Identify().setOnce(key, value)
      amplitude.getInstance().identify(identify)
    }
  }
}
