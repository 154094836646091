import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useUserStore } from '@/stores/user'
import Image from 'next/image'
import titleImage from 'public/images/scarlett-title-image.png'
import usTitleImage from 'public/images/scarlett-title-image-us.png'
import { Button, Loading } from '@nextui-org/react'
import { createUser, fetchUserByPhone } from '@/apis/users'
import { User, UtmInfo } from '@/apis/users/index.d'
import { identifyUserProperty, makeLog } from '@/utils/fpixel'
import { Meta } from '@/layouts/Meta'
import config from '@/config'
import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { SCARLETT_MARKET_GLOBAL } from '@/utils/constants'
import { Footer } from '@/components/Footer'
import * as Sentry from '@sentry/nextjs'
import { errorLog } from '@/utils/errorLogConstants'
import { Container } from '@/components/Container'
import { infoStatus } from '@/utils/infoStatus'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { E164Number } from 'libphonenumber-js/types'
import 'react-phone-number-input/style.css'
import { countryMap } from '@/utils/countryMap'
import { CountryCode } from 'libphonenumber-js/types'
import { languageMap } from '@/utils/languageMap'
import { ConceptPackageContainer } from '@/components/ConceptPackage/ConceptPackageContainer'

export const IndexPage = () => {
  const router = useRouter()
  const { utm_source, utm_adset, utm_campaign, utm_content, from, country_code } = router.query
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState<E164Number>('')
  const [isFirstView, setIsFirstView] = useState<boolean>(true)
  const {
    name,
    countryCode,
    setModel,
    setName,
    setUserId,
    setUuid,
    setPhoneNumber,
    utmState,
    setUtmState,
    setCountryCode,
  } = useUserStore()
  // kr phoneNumber to e164 format
  const [nameErrorMessage, setNameErrorMessage] = useState<string | null>(null)
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState<string | null>(null)
  const languageCode =
    countryCode === 'JP' ? 'ja' : countryCode === 'TW' || countryCode === 'HK' ? 'zh' : ''
  // Regex not allow numbers
  const nameRegex = /^[^0-9]*$/ // 숫자만 아니면 됨
  const loginMutation = useMutation<
    User | undefined,
    undefined,
    { name: string; phone: string; utmInfo: UtmInfo | null }
  >({
    mutationFn: async ({ name, phone, utmInfo }) => {
      // 유저 조회
      let user: User | undefined
      try {
        user = await fetchUserByPhone(phone)
      } catch (_) {
        // Ignore
      }
      if (user) return user
      // 유저 생성
      return await createUser(name, phone, countryCode?.toString()?.toUpperCase() ?? null, utmInfo)
    },
    mutationKey: 'login',
  })

  const login = async () => {
    setLoading(true)
    makeLog('click_login', { phone, name: name })
    // Reset Error Message
    setPhoneNumberErrorMessage(null)
    setNameErrorMessage(null)
    if (!phone) {
      setPhoneNumberErrorMessage('Please enter your phone number.')
      scrollToBottom()
      setLoading(false)
      return
    }
    if (!isValidPhoneNumber(phone)) {
      setPhoneNumberErrorMessage('Wrong phone number format.')
      scrollToBottom()
      setLoading(false)
      return
    }
    if (!name) {
      setNameErrorMessage('Please enter your name.')
      scrollToBottom()
      setLoading(false)
      return
    }
    // 유저 정보
    const user = await loginMutation.mutateAsync({
      name,
      phone,
      utmInfo: utmState,
    })
    const userId = user?.id
    const uuid = user?.uuid
    const latestModel = user?.latestModel
    // 로그인 혹은 회원가입 실패시
    if (!userId || !uuid) {
      Sentry.captureException(new Error('Login failed'))
      Sentry.addBreadcrumb({
        category: errorLog.login,
        data: {
          domain: window?.location?.href,
          phone,
          name,
        },
      })
      toast.error('Login failed. Please try again.')
      makeLog('login_error', {})
      return
    }
    // 로그인 성공시 스토어 업데이트
    setModel(latestModel)
    setPhoneNumber(String(user?.phone))
    setName(String(user?.name))
    setUserId(userId)
    setUuid(uuid)
    setCountryCode(user.countryCode)
    Sentry.setUser({
      id: String(userId),
      username: user?.name,
      segment: user?.utmInfo?.utm_source || user?.utmInfo?.from_service || 'unknown',
    })
    makeLog('login_success', {})
    for (const key in user) {
      if (user.hasOwnProperty(key)) {
        const value = user[key as keyof User]
        identifyUserProperty(key, value)
      }
    }
    // 페이지 이동 경로
    const nextRoute = (router.query.redirect as string) ?? '/'
    // 있는데 trained가 false면 모델링 중이므로 대기 페이지로 이동
    if (latestModel && !latestModel.trained) {
      router.push(`/info/${infoStatus.ALREADY_UPLOADED}`)
      return
    }
    // 모델이 없거나, 모델이 있고 트레이닝 끝났으면 concept-select 페이지로 이동
    router.push(`/concept-select?redirect=${nextRoute}`)
    setLoading(false)
  }
  /**
   * 증명 사진 만들기 페이지 이동
   */
  async function moveToCreatePage() {
    await login()
  }
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (nameRegex.test(e.target.value)) {
      setName(e.target.value)
    } else {
      toast.error('Name cannot contain special characters or numbers.')
    }
  }
  const isBrowser = () => typeof window !== 'undefined'
  // TODO scroll 방법 변경
  const scrollToBottom = () => {
    if (!isBrowser()) return
    window.scrollTo(0, document.body.scrollHeight)
  }
  useEffect(() => {
    // 첫 접속 때 coutry code 세팅
    if (router.isReady) {
      if (country_code) {
        setCountryCode(country_code?.toString()?.toUpperCase())
      } else if (!country_code && !countryCode) {
        // fallback으로 가장 금액 높은 일본
        setCountryCode('JP')
      }
    }
  }, [router.isReady, country_code])
  useEffect(() => {
    if (router.isReady) {
      if (isFirstView) {
        if (utmState) {
          makeLog('view_first_page_again', {
            from: from ? String(from) : 'referral_user_empty',
          })
        } else {
          const utmInfo: UtmInfo = {
            utm_source: utm_source ? String(utm_source) : 'organic',
            utm_campaign: utm_campaign ? String(utm_campaign) : null,
            utm_adset: utm_adset ? String(utm_adset) : null,
            utm_content: utm_content ? String(utm_content) : null,
            from: from ? String(from) : null,
            from_service: SCARLETT_MARKET_GLOBAL,
          }
          setUtmState({ ...utmInfo })
          identifyUserProperty(
            'countryCode',
            country_code?.toString()?.toUpperCase() || countryCode,
          )
          identifyUserProperty('utmInfo', { ...utmInfo })
          makeLog('view_first_page', { from: from ? String(from) : 'referral_user_empty' })
        }
        setIsFirstView(() => false)
      }
    }
  }, [router.isReady, utm_source, utm_campaign, utm_adset, utm_content, from, isFirstView])
  return (
    <div
      style={{ maxWidth: '500px' }}
      className="w-full h-full mx-auto"
    >
      <Meta
        title={config.get('siteName')}
        description={config.get('description')}
      />
      <Container isFullWidth>
        <>
          <div className={classNames(['flex', 'flex-col', 'text-center'])}>
            <Image
              src={country_code === 'us' ? usTitleImage : titleImage}
              alt="image"
              placeholder="blur"
              className="w-full"
            />

            <SaleInfo
              countryCode={countryCode}
              languageCode={languageCode}
            />
            <Description
              countryCode={countryCode}
              languageCode={languageCode}
            />
          </div>

          {countryCode === 'US' && <ConceptPackageContainer />}

          {countryCode !== 'US' && (
            <div className={classNames(['p-6'])}>
              <div className={classNames(['mt-4', 'flex', 'flex-col', 'gap-y-3'])}>
                {countryCode && (
                  <PhoneInput
                    addInternationalOption={false}
                    onCountryChange={(country) => {
                      makeLog('change_country_code', {
                        from: countryCode?.toString(),
                        to: country?.toString(),
                      })
                      setCountryCode(
                        country?.toString() ?? country_code?.toString()?.toUpperCase() ?? 'JP',
                      )
                    }}
                    defaultCountry={
                      (country_code?.toString()?.toUpperCase() as CountryCode) ??
                      (countryCode as CountryCode) ??
                      ('JP' as CountryCode)
                    }
                    countries={Object.keys(countryMap) as CountryCode[]}
                    numberInputProps={{
                      className: 'border border-black p-4 outline-none w-full',
                      placeholder: 'Phone number',
                    }}
                    value={phone}
                    onChange={(phone) => {
                      if (phone) {
                        setPhone(phone)
                      }
                    }}
                  />
                )}
                {phoneNumberErrorMessage && (
                  <div className={classNames(['text-red-500', 'px-2'])}>
                    {phoneNumberErrorMessage}
                  </div>
                )}
              </div>

              <div className={classNames(['mt-2', 'flex', 'flex-col', 'gap-y-3'])}>
                <input
                  className={classNames([
                    'border',
                    'border-black',
                    'p-4',
                    'outline-none',
                    'w-full',
                    {
                      '!border-red-500': nameErrorMessage,
                    },
                  ])}
                  placeholder="Name"
                  value={name && nameRegex.test(name) ? String(name) : ''}
                  onChange={handleNameChange}
                />
                {nameErrorMessage && (
                  <div className={classNames(['text-red-500', 'px-2'])}>{nameErrorMessage}</div>
                )}
              </div>
              <LabelBelowInput
                countryCode={countryCode}
                languageCode={languageCode}
              />
            </div>
          )}
          <div style={{ height: '100px' }}></div>
          {countryCode !== 'US' && (
            <div
              className="fixed bottom-0 left-0 right-0 bg-white flex flex-row justify-center"
              style={{ zIndex: 1000 }}
            >
              <Button
                className="mb-7 mt-4 w-full mx-10 max-w-md"
                rounded={true}
                style={{ width: '100%', height: '54px' }}
                disabled={loading || !isValidPhoneNumber(phone)}
                onClick={() => moveToCreatePage()}
              >
                {loading ? (
                  <Loading
                    size="sm"
                    color="white"
                  />
                ) : countryCode === 'JP' ? (
                  '写真のコンセプトを選ぶ'
                ) : countryCode === 'TW' || countryCode === 'HK' ? (
                  '前往画报主题选择页面'
                ) : (
                  'Choose concepts'
                )}
              </Button>
            </div>
          )}
        </>
      </Container>
      <div className={classNames(['mb-[100px]'])}>
        <Footer
          refundLabel={
            countryCode === 'JP'
              ? '払い戻し規定'
              : countryCode === 'TW' || countryCode === 'HK'
              ? '退款政策'
              : 'Refund Policy'
          }
          countryCode={countryCode}
          privacyLabel={
            countryCode === 'JP'
              ? 'プライバシーポリシー'
              : countryCode === 'TW' || countryCode === 'HK'
              ? '隱私政策'
              : 'Privacy Notice'
          }
        />
      </div>
    </div>
  )
}

interface DescriptionProps {
  countryCode: string | null
  languageCode: string
}

export const Description = ({ countryCode, languageCode }: DescriptionProps) => {
  const languageInfo = languageMap[languageCode]

  if (countryCode === 'JP' || countryCode === 'TW' || countryCode === 'HK') {
    return (
      <div className={classNames(['bg-gray-100', 'text-left', 'p-4', 'whitespace-pre-line'])}>
        <span className={classNames(['font-medium', 'text-xl'])}>
          {languageInfo?.weArePeekaboo}
        </span>
        <br />
        <br />
        <span className={classNames(['font-light', 'text-lg', 'mt-2'])}>
          {languageInfo?.weUseAI}
        </span>
        <br />
        <br />
        <span
          className={classNames(['font-bold', 'underline', 'text-[#1984E6]'])}
          onClick={() => {
            window.open('https://www.instagram.com/peekaboo._.studio/', '_self')
            makeLog('click_instagram', {})
          }}
        >
          ({languageInfo?.goToOfficialIG})
        </span>
        <br />
        <br />
        <span className={classNames(['font-light', 'text-lg', 'mt-2'])}>
          {languageInfo?.celebrateOpeningOneMonth}
          <br />

          {countryCode === 'JP' ? (
            <>
              <span className="font-medium">
                {countryMap[countryCode].currencyFormat?.replace(
                  '{amount}',
                  `${Math.round(countryMap[countryCode].price / 0.06)?.toLocaleString()}`,
                )}
              </span>
              <span className="ml-2">{languageInfo?.originalPrice}</span>
              <br />
              <span className={classNames(['font-medium', 'text-red'])}>
                {countryMap[countryCode].currencyFormat?.replace(
                  '{amount}',
                  `${countryMap[countryCode].price?.toLocaleString()}`,
                )}
              </span>
              <span className="ml-2">{languageInfo?.discountPrice}</span>
            </>
          ) : (
            <>
              我們將{' '}
              <span className="font-medium">
                {countryMap[countryCode].currencyFormat?.replace(
                  '{amount}',
                  `${Math.round(countryMap[countryCode].price / 0.06)?.toLocaleString()}`,
                )}{' '}
              </span>
              的快照/畫報，
              <br />以
              <span className="text-red font-medium">
                {countryMap[countryCode].currencyFormat?.replace(
                  '{amount}',
                  `${countryMap[countryCode].price?.toLocaleString()}`,
                )}
              </span>
              的價格提供。
            </>
          )}
        </span>
        <br />
        <br />
        <span className={classNames(['font-light', 'text-lg', 'mt-2'])}>
          {languageInfo?.formBelow}
        </span>
      </div>
    )
  }

  return (
    <div className={classNames(['bg-gray-100', 'text-left', 'p-4'])}>
      <span className={classNames(['font-medium', 'text-xl'])}>
        Welcome,
        <br />
        This is Peekaboo Studio.
      </span>
      <br />
      <br />
      <span className={classNames(['font-light', 'text-lg', 'mt-2'])}>
        We use Ai system to create professional photographs.
      </span>
      <br />
      <br />
      <span
        className={classNames(['font-bold', 'underline', 'text-[#1984E6]'])}
        onClick={() => {
          window.open('https://www.instagram.com/peekaboo._.studio/', '_self')
          makeLog('click_instagram', {})
        }}
      >
        (Official Instagram account)
      </span>
      <br />
      <br />
      <span className={classNames(['font-light', 'text-lg', 'mt-2'])}>
        To celebrate our grand opening,
        <br />
        We are giving out a
        <br />
        <span className={classNames(['font-medium'])}>94% discount</span> promotion on
        <br />
        &quot;Professional photoshoot package&quot;.
        <br />
        This promotion only runs throughout{' '}
        <span className={classNames(['font-medium'])}>May.</span>
      </span>
      <br />
      <br />
      <span className={classNames(['font-light', 'text-lg', 'mt-2'])}>
        Fill out a form below and submit,
        <br />
        We&apos;ll send you a message when your photos are ready!
      </span>
    </div>
  )
}

interface SaleInfoProps extends DescriptionProps {}

export const SaleInfo = ({ countryCode, languageCode }: SaleInfoProps) => {
  const languageInfo = languageMap[languageCode]
  if (countryCode === 'JP' || countryCode === 'TW' || countryCode === 'HK') {
    return (
      <h2 className={classNames(['text-2xl', 'font-semibold', 'pt-4', 'whitespace-pre-line'])}>
        {languageInfo?.sale}
      </h2>
    )
  }
  return (
    <h2 className={classNames(['text-2xl', 'font-semibold', 'pt-4'])}>
      [94% OFF or Discount]
      <br />
      Professional photoshoot event
    </h2>
  )
}

interface LabelBelowInputProps extends DescriptionProps {}

export const LabelBelowInput = ({ countryCode, languageCode }: LabelBelowInputProps) => {
  const languageInfo = languageMap[languageCode]

  if (countryCode === 'JP' || countryCode === 'TW' || countryCode === 'HK') {
    return (
      <div className={classNames(['mt-3'])}>
        <span className={classNames(['text-gray-600', 'text-sm', 'font-light'])}>
          {languageInfo?.noPhoneNoPhoto}
        </span>
      </div>
    )
  }

  return (
    <div className={classNames(['mt-3'])}>
      <span className={classNames(['text-gray-600', 'text-sm', 'font-light'])}>
        We will send you a text when your final product is ready.
      </span>
    </div>
  )
}

export default IndexPage
