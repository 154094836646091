import { peekabooBackendV1Instance } from '@/apis'
import { IPayPalCaptureOrderRequest, IPayPalPaymentFailRequest } from './index.d'

export const captureOrder = async (request: IPayPalCaptureOrderRequest) => {
  return peekabooBackendV1Instance.post(
    `/orders/${request.orderId}/succeed`,
    {
      amountAttempt: request.amount,
    },
    {
      headers: {
        'x-user-id': request.uuid,
        'Content-type': 'application/json',
      },
    },
  )
}

export const setOrderFailed = async (request: IPayPalPaymentFailRequest) => {
  return peekabooBackendV1Instance.post(
    `/orders/${request.orderId}/fail`,
    {},
    {
      headers: {
        'x-user-id': request.uuid,
        'Content-type': 'application/json',
      },
    },
  )
}
