import Image from 'next/image'
import classNames from 'classnames'
import { Concept } from '@/apis/concepts/index.d'
import CheckBlack from 'public/icons/check-black.svg'
import CheckEmpty from 'public/icons/check-empty.svg'

interface PackageComponentProps {
  concept: Concept
  selected: boolean
  onClick: (id: number) => void
}

export const SinglePackage = ({ concept, selected, onClick }: PackageComponentProps) => {
  return (
    <div className="flex flex-col items-center my-5">
      <div
        className="flex w-full flex-row items-center justify-between mb-5 px-6 cursor-pointer"
        onClick={() => onClick(concept.id)}
      >
        <div className="flex flex-col">
          <p className="font-semibold text-lg mb-0.5">{concept.name}</p>
          <p>
            <span className="line-through font-light text-sm ">
              {Math.floor(concept.price / 0.06)?.toLocaleString()}$
            </span>{' '}
            <span className="font-bold">{concept.price?.toLocaleString()}$</span>
          </p>
        </div>

        <button className="flex">
          {selected ? (
            <CheckBlack className="w-31pxr h-31pxr" />
          ) : (
            <CheckEmpty className="w-31pxr h-31pxr" />
          )}
        </button>
      </div>
      <div className="flex w-full h-64 overflow-x-scroll scrollbar-hide">
        <div className="flex h-64 scrollbar-hide">
          {concept.images.map((imageUrl, index) => {
            return (
              <div
                key={imageUrl}
                className={classNames([
                  'relative',
                  'h-64',
                  index == 0 && 'ml-6',
                  index == concept.images.length - 1 && 'mr-6',
                ])}
                style={{
                  width: (1340 / 2012) * 256,
                }}
              >
                <Image
                  src={`https://ik.imagekit.io/learners/${imageUrl}`}
                  alt="image"
                  width={1340}
                  height={2012}
                  className="absolute top-0 bottom-0 left-0 right-0"
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
