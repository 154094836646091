import { create } from 'zustand'
import { Concept } from '@/apis/concepts/index.d'

type Store = {
    concepts: {[productId: number]: Concept}
}

type Actions = {
    updateConcepts: (productId: number, concept: Concept) => void
}

export const useConceptStore = create<Store & Actions>(
    (set) => ({
        concepts: {},
        updateConcepts: (productId, concept) =>
          set((state) => ({ concepts: { ...state.concepts, [productId]: concept } })),
    })
)