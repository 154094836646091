import { serverBaseUrl, backendBaseUrl } from '@/utils/network';
import axios from 'axios';

// export const peekabooInstance = axios.create({
//   baseURL: `${SERVER_URL}`,
//   headers: { 'Content-type': 'application/json' },
// });

export const peekabooBackendV1Instance = axios.create({
  baseURL: `${backendBaseUrl}`,
  headers: { 'Content-type': 'application/json' },
});

export const peekabooServerInstance = axios.create({
  baseURL: `${serverBaseUrl}`,
  headers: { 'Content-type': 'application/json' },
});

// export const peekabooFileInstance = axios.create({
//   baseURL: `${SERVER_URL}`,
//   headers: { 'Content-Type': 'multipart/form-data' },
// });

// export const peekabooBlobInstance = axios.create({
//   baseURL: `${SERVER_URL}`,
//   responseType: 'blob',
// });
